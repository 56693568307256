.instructionListStyle {
	/* 
	Generic Styling, for Desktops/Laptops 
	*/
	table { 
		width: 100%; 
		border-collapse: collapse;
	}

	th {
		background-color: white;
		font-weight: bold; 
		border-bottom: 1px solid #ccc;
	}
	td, th { 
		padding: 6px; 
		text-align: left;
		vertical-align: middle;
	}

	tr[data-isheader=true] {
		background: #f8f8f8;
		textarea {
			height: 1em;
			max-height: 1em;
			resize: none;
		}
	}

	tr[data-isheader=false] {
		// Add padding to the 'Text' column so it's pushed out and appears as a sub-item in the group
		td:nth-of-type(2) {
			padding-left: 1.25em;
		}
		textarea {
			height: 5.25em;
		}
	}

	/* 
	Max width before this PARTICULAR table gets nasty
	This query will take effect for any screen smaller than 760px
	and also iPads specifically.
	*/
	@media 
	only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { border: 1px solid #ccc; }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 40%;
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 35%; 
			padding-right: 10px;
			white-space: nowrap;
		}
		
		/* For 'actions' do not include the padding for the label/header. It will not be used */
		td:nth-of-type(1) {
			padding-left: 0;
		}

		tr[data-isheader=true] {
			background: #f8f8f8;
			td:nth-of-type(2) {
				padding-left: 0;
			}
			textarea {
				height: 1em;
			}
		}

		textarea {
			height: 10em;
		}
	}
}