.ingredientListViewStyle {
	/* 
	Generic Styling, for Desktops/Laptops 
	*/
	table { 
		width: 100%; 
		border-collapse: collapse;
	}
	/* Zebra striping */
	// tr:nth-of-type(odd) { 
	// 	background: #f8f8f8;
	// }
	th {
		background-color: white;
		font-weight: bold; 
		border-bottom: 1px solid #ccc;
	}
	td, th { 
		padding: 6px; 
		text-align: left;
	}

	/* 
	Max width before this PARTICULAR table gets nasty
	This query will take effect for any screen smaller than 760px
	and also iPads specifically.
	*/
	@media 
	only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { border: 1px solid #ccc; }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 40%;
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 1px;
			left: 6px;
			width: 35%; 
			padding-right: 10px; 
			white-space: nowrap;
			font-weight: bold;
		}

		/* For 'Status', 'Save', 'Delete' do not include the padding for the label/header. It will not be used */
		td:nth-of-type(1) {
			padding-left: 0;
			text-align: center;
		}

		td:nth-of-type(2) {
			padding-left: 0;
			text-align: center;
		}

		td:nth-of-type(3) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}
		td:nth-of-type(4) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}
		td:nth-of-type(5) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}

		td:nth-of-type(6) {
			padding-left: 0;
			text-align: center;
		}

		// Label the data
		// no 'actions' label
		// td:nth-of-type(1):before { content: "Indicator"; }
		// td:nth-of-type(2):before { content: "Save"; }
		td:nth-of-type(3):before { content: "Name"; }
		td:nth-of-type(4):before { content: "Default unit"; }
		td:nth-of-type(5):before { content: "Default department"; }
		// td:nth-of-type(6):before { content: "Delete"; }
	}
}