.groceryListStyle {
	/* 
	Generic Styling, for Desktops/Laptops 
	*/
	table { 
		width: 100%; 
		border-collapse: collapse;
	}
	/* Zebra striping */
	tr:nth-of-type(odd) { 
		background: #f8f8f8;
	}
	th {
		background-color: white;
		font-weight: bold; 
		border-bottom: 1px solid #ccc;
	}
	td, th {
		padding: 6px; 
		text-align: left; 
	}

	/* 
	Max width before this PARTICULAR table gets nasty
	This query will take effect for any screen smaller than 760px
	and also iPads specifically.
	*/
	@media 
	only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { border: 1px solid #ccc; }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 40%;
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 1px;
			left: 6px;
			width: 35%; 
			padding-right: 10px; 
			white-space: nowrap;
			font-weight: bold;
		}

		/* https://css-tricks.com/a-complete-guide-to-data-attributes/ */
		/* To use, just put 'data-hideit' on an element as an attribute */
		[data-hideit] {
			display: none;
		}
		
		
		// Actions
		// td:nth-of-type(2) {
		
		// }

		// Item
		td:nth-of-type(3) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}

		// Recipe - do not show recipe in td if the item is not for a recipe
		tr[data-isfromrecipe=false] {
			td:nth-of-type(4) {
				display: none;
			}
		}
		tr[data-isfromrecipe=true] {
			td:nth-of-type(4) {
				padding-left:0;
				left: 3px;
				padding-top: 25px;
			}
		}

		// Qty
		td:nth-of-type(5) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}

		// unit
		td:nth-of-type(6) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}

		// department
		td:nth-of-type(7) {
			padding-left:0;
			left: 3px;
			padding-top: 25px;
		}

		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "Index"; }
		/* no 'Reorder' label */
		/* td:nth-of-type(2):before { content: "Reorder"; } */
		td:nth-of-type(3):before { content: "Item"; }
		td:nth-of-type(4):before { content: "Recipe"; }
		td:nth-of-type(5):before { content: "Qty"; }
		td:nth-of-type(6):before { content: "UoM"; }
		td:nth-of-type(7):before { content: "Department"; }
		/* no 'Delete' label */
		/* td:nth-of-type(8):before { content: "Delete"; } */
	}
}