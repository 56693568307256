
// Required imports in order to reference the input-border-color variable
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

.inputControl {
	position: relative;
	
	input {
		height: 3rem;
	}

	label {
		position: absolute;
		left: 15px;
		top: -1rem;
		margin-bottom: 0;
		padding-bottom: 0;
		background-color: $card-bg;
		border-radius: 40%;
		padding-left: 10px;
		padding-right: 10px;
		// https://getbootstrap.com/docs/5.0/customize/color/
		color: $gray-600;
	}

	span {
		position: absolute;
		right: 15px;
		bottom: -.5rem;
		background-color: $card-bg;
		padding-left: 10px;
		padding-right: 10px;
		color: $gray-600;
	}

	// Small screen
	@media 
	only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
		label {
			left: 5px;
		}
	}
}