
// Required imports in order to reference the input-border-color variable
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";


.smallFormWidth {
	// small form
	@media 
	only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
		width: 100%;
	}
}

.smallFormBodyCard {
	margin-top: 10px;
	width: 75%;
	margin-left: auto;
	margin-right: auto;

	@media 
	only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
		width: 100%;
	}
}