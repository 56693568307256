/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.main-content {
  // border: 1px solid red;
  // padding: 1px;
  // margin: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}

.overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.fs-smaller {
  font-size: smaller;
}

// #text{
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   font-size: 50px;
//   color: white;
//   transform: translate(-50%,-50%);
//   -ms-transform: translate(-50%,-50%);
// }